import React, { useState } from "react";

const ShopTopBar = ({ onShowCountChange, onSortByChange, onSearch }) => {
	const [showCount, setShowCount] = useState("09");
	const [sortBy, setSortBy] = useState("Name");
	const [searchQuery, setSearchQuery] = useState("");

	const handleShowCountChange = (event) => {
		setShowCount(event.target.value);
		onShowCountChange(event.target.value);
	};

	const handleSortByChange = (event) => {
		setSortBy(event.target.value);
		onSortByChange(event.target.value);
	};
	const handleSearchChange = (event) => {
		const newQuery = event.target.value;
		setSearchQuery(newQuery);
		onSearch(newQuery);

		if (newQuery === "") {
			// Trigger loading all products when the search query is cleared
			onSearch();
		}
	};
	const handleSearchSubmit = (event) => {
		event.preventDefault();
		onSearch(searchQuery);
	};

	const shopTop = {
		backgroundColor: "#e84545",
		color: "white",
		padding: "10px",
		display: "flex",
	};

	const shopTopStyle = {
		color: "white",
		padding: "10px",
		display: "flex",
		justifyContent: "space-between",
		width: "100%",
		alignItems: "center",
	};

	const inputStyle = {
		color: "black",
	};

	return (
		<div className="shop-top" style={shopTop}>
			<div className="row " style={shopTopStyle}>
				<div className="col">
					<div className="single-shorter">
						<label>Show :</label>
						<select
							style={{
								border: "1px solid white",
							}}
							value={showCount}
							onChange={handleShowCountChange}
						>
							<option value="09">09</option>
							<option value="15">15</option>
							<option value="25">25</option>
							<option value="30">30</option>
						</select>
					</div>
				</div>
				<div className="col">
					<form
						onSubmit={handleSearchSubmit}
						className="single-shorter"
						style={{ display: "flex" }}
					>
						<input
							style={{
								border: "1px solid white",
							}}
							type="text"
							placeholder="Search"
							value={searchQuery}
							onChange={handleSearchChange}
						/>
						<button
							type="submit"
							style={{
								color: "white",
								background: "Transparent",
								border: "None",
							}}
						>
							<i className="bi bi-search"></i>
						</button>
					</form>
				</div>
			</div>
		</div>
	);
};

export default ShopTopBar;

import React from "react";
import { Link } from "react-router-dom";

import "../assets/shop/css/bootstrap.css";
import "../assets/shop/css/magnific-popup.min.css";
import "../assets/shop/css/font-awesome.css";
import "../assets/shop/css/jquery.fancybox.min.css";
import "../assets/shop/css/themify-icons.css";
import "../assets/shop/css/niceselect.css";
import "../assets/shop/css/animate.css";
import "../assets/shop/css/flex-slider.min.css";
import "../assets/shop/css/slicknav.min.css";
import "../assets/shop/css/style.css";
import "../assets/shop/css/responsive.css";

const Sidebar = () => {
	return (
		<div className="col-lg-3 col-md-4 col-12">
			{/* Add further details and functionalities for each section as needed */}
			<div className="shop-sidebar">
				{/* Categories */}
				<div className="single-widget category">
					<h3 className="title">Categories</h3>
					<ul class="categor-list">
						<li>
							<Link to="/products?category=2">Building Signs</Link>
						</li>
						<li>
							<Link to="/products?category=1">Outdoor Signs</Link>
						</li>
						<li>
							<Link to="/products?category=3">Indoor Signs</Link>
						</li>
						<li>
							<Link to="/products?category=4">Vehhicle Signs</Link>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default Sidebar;

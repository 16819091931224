import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
	const [isNavOpen, setIsNavOpen] = useState(false);
	const [scrolled, setScrolled] = useState(false);
	const [dropdownsOpen, setDropdownsOpen] = useState({});

	const toggleNav = () => {
		setIsNavOpen(!isNavOpen);
		// Close all dropdowns when nav is toggled
		setDropdownsOpen({});
	};

	const toggleDropdown = (dropdownId) => {
		setDropdownsOpen((prevState) => ({
			...prevState,
			[dropdownId]: !prevState[dropdownId],
		}));
	};

	const handleScroll = () => {
		const offset = window.scrollY;
		setScrolled(offset > 100);
	};

	const handleNavLinkClick = () => {
		// Close the nav only if it's open
		if (isNavOpen) {
			toggleNav();
		}
	};

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<header
			id="header"
			className={`header fixed-top d-flex align-items-center ${
				scrolled ? "scrolled" : ""
			} ${isNavOpen ? "mobile-nav-active" : ""}`}
		>
			<div className="container-fluid d-flex align-items-center justify-content-between">
				<a href="/" className="logo d-flex align-items-center me-auto me-xl-0">
					<h1>Sign Shop Online</h1>
					<span>.</span>
				</a>
				<nav id="navmenu" className="navmenu">
					<ul>
						<li onClick={handleNavLinkClick}>
							<Link to="/" className="active">
								Home
							</Link>
						</li>
						<li
							className={`dropdown has-dropdown ${
								dropdownsOpen["products"] ? "active" : ""
							}`}
						>
							<Link
								to="/products"
								onClick={(e) => {
									e.preventDefault();
									toggleDropdown("products");
								}}
							>
								<span>Product</span> <i className="bi bi-chevron-down"></i>
							</Link>
							<ul
								className="dd-box-shadow"
								style={{
									display: dropdownsOpen["products"] ? "block" : "none",
								}}
							>
								<li onClick={handleNavLinkClick}>
									<Link to="/products?category=1">Building Signs</Link>
								</li>
								<li onClick={handleNavLinkClick}>
									<Link to="/products?category=2">Outdoor Signs</Link>
								</li>
								<li onClick={handleNavLinkClick}>
									<Link to="/products?category=3">Indoor Signs</Link>
								</li>
								<li onClick={handleNavLinkClick}>
									<Link to="/products/vehicle-signs">Vehicle Signage</Link>
								</li>
								<li onClick={handleNavLinkClick}>
									<Link to="/products/safety-signs">Safety Signage</Link>
								</li>
								<li onClick={handleNavLinkClick}>
									<Link to="/products/fire-equipment">Fire Equipment</Link>
								</li>
								<li onClick={handleNavLinkClick}>
									<Link to="/products/ppe">PPE</Link>
								</li>
								<li onClick={handleNavLinkClick}>
									<Link to="/products/first-aid-equipment">
										First Aid Equipment
									</Link>
								</li>
								<li onClick={handleNavLinkClick}>
									<Link to="/products/signage">Signage</Link>
								</li>
							</ul>
						</li>
						<li onClick={handleNavLinkClick}>
							<Link to="/ServicesPage">Services</Link>
						</li>
						<li onClick={handleNavLinkClick}>
							<Link to="/News">News</Link>
						</li>
						<li onClick={handleNavLinkClick}>
							<Link to="/Contact">Contact us</Link>
						</li>
					</ul>
					<i
						className={`mobile-nav-toggle d-xl-none ${
							isNavOpen ? "bi-x" : "bi-list"
						}`}
						onClick={toggleNav}
					></i>
				</nav>
				<Link to="/Contact" className="btn-getstarted">
					<p className="targetedPNav" style={{ margin: 0 }}>
						010 746 3547 / 071 871 5123
					</p>
				</Link>
			</div>
		</header>
	);
};

export default Navbar;

import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css"; // Importing Bootstrap styles
import AOS from "aos";
import "aos/dist/aos.css"; // Importing AOS (Animate On Scroll) library styles
import { Helmet } from "react-helmet";
// Importing components
import Navbar from "./components/Navbar";
import Home from "./views/Home";
import ShopPage from "./views/ShopPage";
import ServicesPage from "./views/ServicesPage";
import NewsPage from "./views/BlogPage";
import ContactSection from "./views/ContactUs";
import ProductDetails from "./views/ProductDetails";
import ResourseDetails from "./views/Resource";
import Creative from "./views/Creative";
import Install from "./views/Install";
import Painting from "./views/Painting";
import ProductManage from "./views/ProductManage";

// This Is a router its use to route to diffent pages
function App() {
	// Initialize AOS (Animate On Scroll) library on component mount
	useEffect(() => {
		AOS.init({
			duration: 1000, // Animation duration
			once: true, // Animation only occurs once as you scroll down
		});
	}, []);

	// Load Script Into The App

	return (
		<Router>
			<Helmet>
				<title>
					Comprehensive Sign Solutions: LED Neon, Safety, Stop, Outdoor & Indoor
					Signs - Custom & Efficient - SignShopOnline
				</title>
				<meta
					name="description"
					content="Explore our diverse range of signs: LED neon, safety, stop, outdoor, indoor, and more. Tailor-made for businesses, events, and home décor. Embrace energy efficiency and durability with SignShopOnline."
				/>
				<meta
					name="keywords"
					content="comprehensive sign solutions, LED neon signs, custom signage, energy-efficient signs, safety signs, stop signs, outdoor signs, indoor signs, business signage, event signs, home décor, SignShopOnline"
				/>
				<meta name="product" content="Comprehensive Signage Solutions" />
				<meta name="brand" content="SignShopOnline" />
				<meta name="category" content="Custom Signs" />
				<meta name="subcategory1" content="LED Neon Signs" />
				<meta name="subcategory2" content="Safety Signs" />
				<meta name="subcategory3" content="Stop Signs" />
				<meta name="subcategory4" content="Outdoor Signs" />
				<meta name="subcategory5" content="Indoor Signs" />
				<meta name="price" content="Varies based on customization" />
				<meta name="availability" content="Made to Order" />
				<meta name="color" content="Multiple color options" />
				<meta name="size" content="Custom sizes available" />
				<meta name="material" content="Durable Materials" />
				<meta name="rating" content="4.8" />
				<meta name="reviewCount" content="85 Reviews" />
				<meta name="feature1" content="Energy-efficient lighting" />
				<meta name="feature2" content="Customizable designs" />
				<meta name="feature3" content="Suitable for indoor and outdoor use" />
				<meta name="feature4" content="Weather-resistant options" />
				<meta name="feature5" content="Variety of color and font choices" />
				<meta name="feature6" content="Eco-friendly LED technology" />
				// Add any other specific and relevant tags for your product
			</Helmet>
			<div
				className="App index-page"
				data-bs-spy="scroll"
				data-bs-target="#navmenu"
			>
				{/* Navbar component is included and it will be displayed on all pages */}
				<Navbar />

				{/* Setting up routes for navigation between different components/pages */}
				<Routes>
					<Route path="/" element={<Home />} />
					{/* All Our Products */}
					{/* copy all links from signs for sa here */}
					<Route path="/products" element={<ShopPage />} />
					<Route path="/products/safety-signs" element={<ShopPage />} />
					<Route path="/products/vehicle-signs" element={<ShopPage />} />
					<Route path="/products/fire-equipment" element={<ShopPage />} />
					<Route path="/products/safety-signs" element={<ShopPage />} />
					<Route path="/products/ppe" element={<ShopPage />} />
					<Route path="/products/first-aid-equipment" element={<ShopPage />} />
					<Route path="/products/signage" element={<ShopPage />} />
					<Route path="/ServicesPage" element={<ServicesPage />} />
					<Route path="/ProductManage" element={<ProductManage />} />
					<Route path="/Painting" element={<Painting />} />
					<Route path="/Install" element={<Install />} />
					<Route path="/News" element={<NewsPage />} />
					<Route path="/Contact" element={<ContactSection />} />
					<Route path="/ResourseDetails" element={<ResourseDetails />} />
					<Route path="/Creative" element={<Creative />} />
					{/* Route for product details page with dynamic productId parameter */}
					<Route path="/custom-signs/:signSlug" element={<ProductDetails />} />
				</Routes>
			</div>
		</Router>
	);
}

export default App;

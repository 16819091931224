import { Link } from "react-router-dom";
function ResourseDetails() {
	return (
		<main id="main">
			{/* Services Details Page Title & Breadcrumbs */}
			<div className="page-title" data-aos="fade">
				<div className="heading">
					<div className="container">
						<div className="row d-flex justify-content-center text-center">
							<div className="col-lg-8">
								<h1>Resources</h1>
								<p className="mb-0">
									Signs4SA is not just your typical signage company; they
									position themselves as an extension of your marketing team. In
									the fast-paced world of business, they understand that you
									don't have the time to micromanage every aspect of your
									company. They aim to alleviate your worries and ensure that
									your brand, image, and personality are consistently
									represented in the right way. With a growing network of
									signage solutions, Signs4SA strives to be more than just a
									service provider. They are ready to collaborate with you from
									concept to execution. Whether you have a fully formed idea,
									need creative input, or are starting with a blank canvas,
									Signs4SA can guide you through the entire process, ensuring
									that your signage and marketing needs are met effectively.
								</p>
							</div>
						</div>
					</div>
				</div>
				<nav className="breadcrumbs">
					<div className="container">
						<ol>
							<li>
								<a href="/home">Home</a>
							</li>
							<li className="current">
								<a href="/services">Services Details</a>
							</li>

							<li className="current">Resources</li>
						</ol>
					</div>
				</nav>
			</div>
			{/* End Page Title */}

			{/* Service-details Section - Services Details Page */}
			<section id="service-details" className="service-details">
				<div className="container">
					<div className="row gy-5">
						<div className="col-lg-12" data-aos="fade-up" data-aos-delay="100">
							{/* Services List */}

							{/* End Services List */}
							{/* ... additional service boxes if needed ... */}
						</div>

						<div className="col-lg-4" data-aos="fade-up" data-aos-delay="200">
							<img
								src="./assets/images/n.png"
								alt="Services"
								className="img-fluid services-img"
								style={{ height: "750px" }}
							/>

							<Link
								to="/ServicesPage"
								type="submit"
								className="btn-CustomToTheme"
								value="Get A Qoute"
							>
								<i className="bi bi-arrow-left"></i>
								<span>Back</span>
							</Link>
						</div>

						<div
							className="col-lg-8 ps-lg-5"
							data-aos="fade-up"
							data-aos-delay="200"
							id="brandaudits"
						>
							{/* Services Image */}
							<h3>Product and Industry Guides</h3>
							<p>
								With over two decades of experience in the signage industry,
								Signs4SA has consolidated their knowledge into a comprehensive
								array of product and industry guides. These guides are designed
								to empower you to make informed decisions for your next signage
								project, drawing on their wealth of expertise. At Signs4SA,
								their mission is to introduce their sign services and extend a
								helping hand to enhance your business's visibility and appeal.
								They have a proven track record of creating effective and
								eye-catching signs that set businesses apart and attract a
								greater customer base.
							</p>
							<p>
								Their extensive range of sign services covers everything from
								design and fabrication to installation and maintenance. Signs4SA
								can craft a diverse range of signage solutions, including
								storefront signs, banners, vehicle wraps, trade show displays,
								and more. Their commitment to quality is evident in their use of
								high-quality materials and modern technology, ensuring that
								their signs are not only visually striking but also durable and
								weather-resistant.
							</p>
							Here are some key benefits of partnering with Signs4SA for your
							signage needs:
							<p></p>
							{/* You might list some key benefits or features of your services here */}
							<ul>
								<li>
									<i className="bi bi-check-circle"></i> Increased Visibility
									and Brand Recognition.
								</li>
								<li>
									<i className="bi bi-check-circle"></i> Cost-Effective
									Marketing.
								</li>
								<li>
									<i className="bi bi-check-circle"></i> Customization.
								</li>
								<li>
									<i className="bi bi-check-circle"></i> Expertise and
									Experience.
								</li>
							</ul>
							{/* Additional service details can be added here */}
							<p>
								With a team boasting years of experience in the sign industry,
								Signs4SA has collaborated with businesses of all sizes and
								types. They can offer valuable guidance and recommendations to
								help you maximize the return on your sign investment. If you're
								intrigued by their sign services or wish to discuss your
								specific requirements, don't hesitate to reach out. Signs4SA is
								ready to provide you with a free consultation and estimate. If
								you're interested in enhancing your business through signage,
								their team of experts is just a phone call away, ready to assist
								you with safety regulations and create eye-catching signage
								tailored to your precise needs.
							</p>
							<Link
								to="/Contact"
								type="submit"
								className="btn-CustomToTheme"
								value="Get A Qoute"
							>
								Get A Qoute For Free!
							</Link>
						</div>
					</div>
				</div>
			</section>
			{/* End Service-details Section */}
		</main>
	);
}

export default ResourseDetails;

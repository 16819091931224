import React, { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";

const ProductDetails = () => {
	// Set Product in Array
	const [product, setProduct] = useState(null);
	// Get The Sign Id from the slug

	const { signSlug } = useParams();
	const [searchParams] = useSearchParams();
	const signId = searchParams.get("id");

	const getCategoryName = (categoryId) => {
		switch (categoryId) {
			case "1":
				return "Building Signs";
			case "2":
				return "Outdoor Signs";
			case "3":
				return "Indoor Signs";
			case "4":
				return "Vehicle Signs";
			default:
				return "Safety Signs";
		}
	};

	useEffect(() => {
		const fetchProduct = async () => {
			try {
				// Replace 'your_php_endpoint' with the actual endpoint where your PHP script is hosted
				const url = `https://www.call-trax.co.za/assets/vendor/Get_Sign.php?index=${signId}`;
				const response = await fetch(url);

				if (!response.ok) {
					throw new Error(`HTTP error! Status: ${response.status}`);
				}

				const data = await response.json();
				console.log(data);
				setProduct(data);
			} catch (error) {
				console.error("Fetching error: ", error);
			}
		};

		if (signId) {
			fetchProduct();
		}
	}, [signId]); // Dependency array to re-run the effect when signId changes

	if (!product) {
		return <div>Loading product details...</div>;
	}

	//Merely load the information inside this return
	return (
		<main id="main">
			<div data-aos="fade" className="page-title">
				<div className="heading">
					<div className="container">
						<div className="row d-flex justify-content-center text-center">
							<div className="col-lg-8">
								<h1>Product Details: {product.name}</h1>
								<p className="mb-0">{product.description}</p>
							</div>
						</div>
					</div>
				</div>
				<nav class="breadcrumbs">
					<div class="container">
						<ol>
							<li>
								<a href="index.html">Home</a>
							</li>
							<li class="current" alt={product.name}>
								{product.name}
							</li>
						</ol>
					</div>
				</nav>
			</div>

			<section id="portfolio-details" className="portfolio-details">
				<div className="container" data-aos="fade-up">
					<div className="portfolio-details-slider swiper">
						<div className="swiper-wrapper align-items-center">
							<div className="swiper-slide">
								<img
									src={`https://www.call-trax.co.za/${product.image_url}`}
									alt={product.name}
								/>
							</div>
						</div>
						{/* Swiper navigation buttons can be included if needed in the future */}
						{/* <div className="swiper-button-prev"></div>
            <div className="swiper-button-next"></div> */}
					</div>

					<div className="row justify-content-between gy-4 mt-4">
						<div className="col-lg-8" data-aos="fade-up">
							<div className="portfolio-description">
								<h2>Client Testimonial</h2>
								<blockquote>
									<p>"{product.testimonial}"</p>
								</blockquote>
								<p>
									<strong>Client Name:</strong> {product.client_name}
								</p>
								<p>
									<strong>Date:</strong> {product.testimonial_date}
								</p>

								<h2>FAQs</h2>
								<p>{product.faqs}</p>

								<h2>More Information</h2>
								<p>{product.moreInformation}</p>
							</div>
						</div>

						<div className="col-lg-3" data-aos="fade-up" data-aos-delay="100">
							<div className="portfolio-info">
								<h3>Project Information</h3>
								<ul>
									<li>
										<strong>Category:</strong>{" "}
										{getCategoryName(product.category_id)}
									</li>
									<a href="#" className="btn-visit align-self-start">
										Get A Qoute
									</a>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
		</main>
	);
};

export default ProductDetails;

import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Footer from "../components/Footer";

const ContactSection = (props) => {
	const [recaptchaValue, setRecaptchaValue] = useState(null);
	const [formData, setFormData] = useState({
		name: "",
		email: "",
		subject: "",
		message: "",
		artwork: null,
	});
	const [isSubmitted, setIsSubmitted] = useState(false);

	const handleRecaptchaChange = (value) => {
		setRecaptchaValue(value);
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevState) => ({ ...prevState, [name]: value }));
	};

	const handleFileChange = (e) => {
		setFormData((prevState) => ({ ...prevState, artwork: e.target.files[0] }));
	};

	//This Function Handles the submission of the form
	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!recaptchaValue) {
			console.error("Please complete the reCAPTCHA");
			return;
		}

		try {
			const response = await fetch("/assets/vendor/Post_Contact_Info.php", {
				// Update the path to your PHP script
				method: "POST",
				body: new URLSearchParams({
					name: formData.name,
					email: formData.email,
					subject: formData.subject,
					message: formData.message,
					"g-recaptcha-response": recaptchaValue, // Include this if your PHP handles reCAPTCHA
				}),
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
			});

			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}

			const result = await response.text();
			console.log(result);
			setIsSubmitted(true); // Set to true when form is successfully submitted
		} catch (error) {
			console.error("There was an error sending the form:", error);
		}
	};

	return (
		<section className="ftco-section">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-md-12">
						<div className="wrapper">
							<div className="row no-gutters mb-5">
								<div className="col-md-7">
									<div className="contact-wrap w-100 p-md-5 p-4">
										<h3 className="mb-4">Contact Us</h3>
										{isSubmitted && (
											<div id="form-message-success" className="mb-4">
												Your message was sent, thank you!
											</div>
										)}
										<form
											method="POST"
											id="contactForm"
											name="contactForm"
											className="contactForm"
											onSubmit={handleSubmit}
										>
											{/* Name Field */}
											<div className="row">
												<div className="col-md-12">
													<div className="form-group">
														<label className="label" htmlFor="name">
															Full Name
														</label>
														<input
															type="text"
															className="form-control"
															name="name"
															id="name"
															placeholder="Name"
															value={formData.name}
															onChange={handleChange}
														/>
													</div>
												</div>
												{/* Email Field */}
												<div className="col-md-12">
													<div className="form-group">
														<label className="label" htmlFor="email">
															Email Address
														</label>
														<input
															type="email"
															className="form-control"
															name="email"
															id="email"
															placeholder="Email"
															value={formData.email}
															onChange={handleChange}
														/>
													</div>
												</div>
												{/* Subject Field */}
												<div className="col-md-12">
													<div className="form-group">
														<label className="label" htmlFor="subject">
															Subject
														</label>
														<input
															type="text"
															className="form-control"
															name="subject"
															id="subject"
															placeholder="Subject"
															value={formData.subject}
															onChange={handleChange}
														/>
													</div>
												</div>
												{/* Message Field */}
												<div className="col-md-12">
													<div className="form-group">
														<label className="label" htmlFor="message">
															Message
														</label>
														<textarea
															name="message"
															className="form-control"
															id="message"
															cols="30"
															rows="4"
															placeholder="Message"
															value={formData.message}
															onChange={handleChange}
														></textarea>
													</div>
												</div>
											</div>
											<ReCAPTCHA
												sitekey="6Lcf3SopAAAAABRdsTN4NwSBRGdoWsc_FZ2zwgCi"
												onChange={handleRecaptchaChange}
											/>

											{/* Submit Button */}
											<div className="col-md-12 mt-3">
												<div className="form-group">
													<input
														type="submit"
														value="Send Message"
														className="btn-CustomToTheme"
													/>
												</div>
											</div>
										</form>
									</div>
								</div>
								<div className="col-md-5 d-flex align-items-stretch">
									<iframe
										title="Google Map"
										src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3586.774401783725!2d28.14009631502907!3d-25.97150228355527!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e9573d92f1e7f99%3A0x2d84e9a14680c64e!2s99%20Tsessebe%20Cres%2C%20Randjespark%2C%20Midrand%2C%201685%2C%20South%20Africa!5e0!3m2!1sen!2sus!4v1665072459753!5m2!1sen!2sus"
										width="100%"
										height="100%"
										style={{ border: 0 }}
										allowFullScreen=""
										loading="lazy"
										referrerPolicy="no-referrer-when-downgrade"
									></iframe>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</section>
	);
};

export default ContactSection;

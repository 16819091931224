import React from "react";
import { Link } from "react-router-dom";
function ProjectDetails() {
	return (
		<main id="main">
			{/* Services Details Page Title & Breadcrumbs */}
			<div className="page-title" data-aos="fade">
				<div className="heading">
					<div className="container">
						<div className="row d-flex justify-content-center text-center">
							<div className="col-lg-8">
								<h1>Printing</h1>
								<p className="mb-0">
									"At Signs4SA, we prioritize vibrant colors and impeccable
									final products for your projects. Our expert printing services
									are tailored to meet your needs, whether it's small stickers,
									decals, or large-scale wall décor. With cutting-edge
									technology and extensive experience, we ensure perfection in
									every print. Our dedicated team of printing experts is ready
									to bring your vision to life with creativity and precision.
									When you choose Signs4SA for your printing needs, you're
									choosing quality that leaves a lasting impact on your project.
									Explore our printing services today and turn your ideas into
									polished realities. Contact us for expert guidance and
									assistance, and experience excellence in every detail of your
									project."
								</p>
							</div>
						</div>
					</div>
				</div>
				<nav className="breadcrumbs">
					<div className="container">
						<ol>
							<li>
								<a href="/home">Home</a>
							</li>
							<li className="current">
								<a href="/services">Services Details</a>
							</li>
							<li className="current">Printing</li>
						</ol>
					</div>
				</nav>
			</div>
			{/* End Page Title */}

			{/* Service-details Section - Services Details Page */}
			<section id="service-details" className="service-details">
				<div className="container">
					<div className="row gy-5">
						<div className="col-lg-12" data-aos="fade-up" data-aos-delay="100">
							{/* Services List */}
							<div className="service-box">
								<h4>Offered Printing Services</h4>
								<div className="services-list">
									<a href="#co">
										<i className="bi bi-circle"></i>

										<span>Correx</span>
									</a>
									<a href="#de">
										<i className="bi bi-circle"></i>
										<span>Decals</span>
									</a>
									<a href="#di">
										<i className="bi bi-circle"></i>
										<span>Digital Printing</span>
									</a>
									<a href="#po">
										<i className="bi bi-circle"></i>
										<span>Posters</span>
									</a>
								</div>
							</div>
							{/* End Services List */}
							{/* ... additional service boxes if needed ... */}
						</div>
						<div className="col-lg-4" data-aos="fade-up" data-aos-delay="200">
							<img
								src="../assets/images/feature3.png"
								alt="Fleet Graphics"
								className="img-fluid services-img"
								style={{ height: "auto", width: "100%" }}
							/>

							<Link
								to="/ServicesPage"
								type="submit"
								className="btn-CustomToTheme"
								value="Get A Qoute"
							>
								<i className="bi bi-arrow-left"></i>
								<span>Back</span>
							</Link>
						</div>

						<div
							className="col-lg-8 ps-lg-5"
							data-aos="fade-up"
							data-aos-delay="200"
							id="co"
						>
							{/* Services Image */}

							<h3>Correx</h3>
							<p>
								At Signs4SA, we know that Correx signs are an economic and
								versatile choice for your signage needs. Correx is a durable
								ribbed plastic material available in various sizes and
								thicknesses. It offers the flexibility to be printed in full
								color with imagery or feature a simple text or graphic-based
								design. Whether you need a quick and cost-effective solution to
								promote a special offer or showcase your brand, Signs4SA has you
								covered with our selection of Correx signs, customized to meet
								your specific needs. We offer Correx signs in 3mm or 5mm
								thicknesses, with options for full-color and UV or
								weather-resistant prints. This makes it an ideal choice for
								outdoor advertising that aligns with your budget.
							</p>

							<p>
								Correx signs find common use in event and festival
								notifications, auctions, estate agent show house announcements,
								'For Sale' signs, and general advertising purposes. They have
								become a popular marketing tool in the signage industry due to
								their waterproof, crack-resistant, and non-toxic properties,
								making them environmentally friendly. Ready to explore the
								benefits of Correx signs for your business? Contact us today,
								and our expert team will assist you every step of the way, from
								safety regulations to creating eye-catching signage that
								fulfills your unique requirements.
							</p>

							{/* You might list some key benefits or features of your services here */}

							{/* Additional service details can be added here */}

							<Link
								to="/Contact"
								type="submit"
								className="btn-CustomToTheme"
								value="Get A Qoute"
							>
								Get A Qoute For Free!
							</Link>
						</div>

						<div className="col-lg-4" data-aos="fade-up" data-aos-delay="200">
							<img
								src="../assets/images/win.png"
								alt="Services"
								className="img-fluid services-img"
								style={{ height: "auto", width: "100%" }}
							/>

							<Link
								to="/ServicesPage"
								type="submit"
								className="btn-CustomToTheme"
								value="Get A Qoute"
							>
								<i className="bi bi-arrow-left"></i>
								<span>Back</span>
							</Link>
						</div>

						<div
							className="col-lg-8 ps-lg-5"
							data-aos="fade-up"
							data-aos-delay="200"
							id="de"
						>
							{/* Services Image */}

							<h3>Decals</h3>
							<p>
								At Signs4SA, we understand the power and versatility of window
								decals. They offer a simple yet effective way to enhance your
								business presence. Our window decals are crafted from various
								vinyl grades, making them suitable for a wide range of
								applications. Window decals can serve as a cost-effective tool
								to promote a flash sale or become a lasting and durable part of
								your window display. Their flexibility shines through as they
								can be easily applied and removed as needed. Whether you need
								simple lettering, information about your trading hours, or
								vibrant full-color graphics for an eye-catching feature window,
								we've got you covered.
							</p>

							<p>
								Interested in leveraging the potential of window decals for your
								business? Contact Signs4SA today. Our team of sign experts is
								ready to assist you, offering expert opinions based on safety
								regulations and creating captivating signage that precisely
								aligns with your specific needs. Let's collaborate and bring
								your vision to life."
							</p>

							<Link
								to="/Contact"
								type="submit"
								className="btn-CustomToTheme"
								value="Get A Qoute"
							>
								Get A Qoute For Free!
							</Link>
						</div>

						<div className="col-lg-4" data-aos="fade-up" data-aos-delay="200">
							<img
								src="../assets/images/print.png"
								alt="Services"
								className="img-fluid services-img"
								style={{ height: "auto", width: "100%" }}
							/>

							<Link
								to="/ServicesPage"
								type="submit"
								className="btn-CustomToTheme"
								value="Get A Qoute"
							>
								<i className="bi bi-arrow-left"></i>
								<span>Back</span>
							</Link>
						</div>

						<div
							className="col-lg-8 ps-lg-5"
							data-aos="fade-up"
							data-aos-delay="200"
							id="di"
						>
							{/* Services Image */}

							<h3>Digital Printing</h3>
							<p>
								At Signs4SA, we proudly offer comprehensive digital printing
								solutions to cater to all your company's needs. If you're in
								need of digital printing services, you've come to the right
								place. Digital printing is a versatile printing technique,
								especially suited for short-run printing projects, and it's a
								specialty service we excel in. No matter what your printing
								requirements may be, our dedicated team strives to meet your
								needs to the best of our abilities. Finding a digital printing
								company that delivers high-quality solutions across a wide range
								of formats, including PVC, canvas, vinyl, and more, can be a
								challenging task. Many printing shops and signage companies lack
								the space and capability to effectively and consistently produce
								large-scale printing to high standards. Fortunately, Signs4SA
								can be your solution.
							</p>

							<p>
								As a leading service provider in the industry, we have the
								expertise and infrastructure to create a diverse range of
								top-quality printed products, including posters, banners, and
								various promotional items. Our digital printing technology
								allows us to work with materials such as self-adhesive vinyl,
								PVC, contra vision, paper, and canvas, ensuring that our
								customers always receive the finest quality products. The
								advantages of digital printing are numerous. It's the ideal
								solution for projects that demand meticulous attention to
								detail. Digital printing is a modern technique where artwork is
								computer-processed and then directly printed onto the surface
								material. Plus, it's eco-friendly, producing less waste in terms
								of plates and chemicals, and offers a faster turnaround by
								eliminating the pre-printing process.
							</p>

							<p>
								If you're considering digital printing for your business or have
								specific printing needs, don't hesitate to get in touch with us.
								Contact Signs4SA, and our expert team will provide you with
								valuable insights based on safety regulations and create
								eye-catching signage and printed materials that precisely align
								with your unique requirements. Let's work together to bring your
								vision to life."
							</p>
							<Link
								to="/Contact"
								type="submit"
								className="btn-CustomToTheme"
								value="Get A Qoute"
							>
								Get A Qoute For Free!
							</Link>
						</div>

						<div className="col-lg-4" data-aos="fade-up" data-aos-delay="200">
							<img
								src="../assets/images/post.png"
								alt="Services"
								className="img-fluid services-img"
								style={{ height: "auto", width: "100%" }}
							/>

							<Link
								to="/ServicesPage"
								type="submit"
								className="btn-CustomToTheme"
								value="Get A Qoute"
							>
								<i className="bi bi-arrow-left"></i>
								<span>Back</span>
							</Link>
						</div>

						<div
							className="col-lg-8 ps-lg-5"
							data-aos="fade-up"
							data-aos-delay="200"
							id="po"
						>
							{/* Services Image */}

							<h3>Posters</h3>
							<p>
								"Looking for high-grade poster printing that caters to all
								industries? Posters remain a classic advertising tool, offering
								marketers a quick and effective means to convey messages. They
								are not only practical but also cost-effective, with the added
								advantage of quick production, making them ideal for promoting
								current events and more. At Signs4SA, we specialize in poster
								printing services for businesses across all sectors. Whether you
								require posters for short-term promotions or long-term
								installations, our state-of-the-art printing technology enables
								us to deliver exceptional photographic posters in any quantity
								or size you need. Our digitally printed posters can be affixed
								in various ways, including easy-change poster frames, unframed,
								block-mounted, or in permanent frames. Whatever your
								requirements, the Signs4SA team is here to assist, providing
								excellent prints guaranteed to effectively convey your message.
							</p>

							<p>
								Posters have stood the test of time as a valuable advertising
								tool, particularly suited for the needs of small to medium-sized
								businesses. We offer numerous advantages, including flexibility
								in size and quantity. Posters are known for their rapid message
								delivery and are often more impactful than flyers or pamphlets.
								We provide continuous exposure, ensuring that your message
								remains visible as long as your posters are on display.
							</p>

							<p>
								Discover the enduring value of posters for your advertising
								needs. Contact us at Signs4SA, and our sign expert will be
								delighted to support your project, offering expert insights
								based on safety regulations and creating captivating signage
								tailored to your unique requirements. Let's collaborate to make
								your message stand out."
							</p>
							<Link
								to="/Contact"
								type="submit"
								className="btn-CustomToTheme"
								value="Get A Qoute"
							>
								Get A Qoute For Free!
							</Link>
						</div>
					</div>
				</div>
			</section>
			{/* End Service-details Section */}
		</main>
	);
}

export default ProjectDetails;

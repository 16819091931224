import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import ShopTopBar from "./ShopTop";
import { Helmet } from "react-helmet";

const ProductArea = () => {
	const [products, setProducts] = useState([]);
	const [filteredProducts, setFilteredProducts] = useState([]);
	const [showCount, setShowCount] = useState(9);
	const [sortBy, setSortBy] = useState("Name");
	const [categoryFromURL, setCategoryFromURL] = useState(null);
	const location = useLocation();

	useEffect(() => {
		const fetchProducts = async () => {
			try {
				const response = await fetch(
					`https://www.call-trax.co.za/assets/vendor/Get_All_Signs.php`
				);
				if (!response.ok) {
					throw new Error(`HTTP error! Status: ${response.status}`);
				}
				const data = await response.json();
				setProducts(data);

				const queryParams = new URLSearchParams(location.search);
				const category = queryParams.get("category");
				setCategoryFromURL(category);
				let filteredData = category
					? data.filter(
							(product) => String(product.category_id) === String(category)
					  )
					: data;

				// Sorting logic
				if (sortBy === "Price") {
					filteredData.sort(
						(a, b) => parseFloat(a.price) - parseFloat(b.price)
					);
				} else if (sortBy !== "Size") {
					filteredData.sort((a, b) => a.name.localeCompare(b.name));
				}

				setFilteredProducts(filteredData.slice(0, showCount));
			} catch (error) {
				console.error("Fetching error: ", error);
			}
		};

		fetchProducts();
	}, [location, showCount, sortBy]);

	const handleShowCountChange = (count) => {
		setShowCount(parseInt(count));
	};

	const handleSortByChange = (sortBy) => {
		setSortBy(sortBy);
	};
	const handleSearch = (query) => {
		let baseProducts = products;
		if (categoryFromURL) {
			baseProducts = baseProducts.filter(
				(product) => String(product.category_id) === String(categoryFromURL)
			);
		}

		if (!query) {
			setFilteredProducts(baseProducts.slice(0, showCount));
		} else {
			const searchResults = baseProducts.filter(
				(product) =>
					typeof product.name === "string" &&
					product.name.toLowerCase().includes(query.toLowerCase())
			);
			setFilteredProducts(searchResults.slice(0, showCount));
		}
	};

	// Function to create URL-friendly slugs
	const slugify = (text) => {
		return text
			.toString()
			.toLowerCase()
			.replace(/\s+/g, "-") // Replace spaces with -
			.replace(/[^\w\-]+/g, "") // Remove all non-word chars
			.replace(/\-\-+/g, "-") // Replace multiple - with single -
			.trim(); // Trim leading/trailing spaces
	};

	if (!products.length) {
		return (
			<div className="loader-container">
				<div className="loader"></div>
			</div>
		); // Display a spinner while products are being fetched
	}

	return (
		<div className="col-lg-9 col-md-8 col-12">
			<Helmet>
				<title>
					Comprehensive Sign Solutions: LED Neon, Safety, Stop, Outdoor & Indoor
					Signs - Custom & Efficient - SignShopOnline
				</title>
				<meta
					name="description"
					content="Explore our diverse range of signs: LED neon, safety, stop, outdoor, indoor, and more. Tailor-made for businesses, events, and home décor. Embrace energy efficiency and durability with SignShopOnline."
				/>
				<meta
					name="keywords"
					content="comprehensive sign solutions, LED neon signs, custom signage, energy-efficient signs, safety signs, stop signs, outdoor signs, indoor signs, business signage, event signs, home décor, SignShopOnline"
				/>
				<meta name="product" content="Comprehensive Signage Solutions" />
				<meta name="brand" content="SignShopOnline" />
				<meta name="category" content="Custom Signs" />
				<meta name="subcategory1" content="LED Neon Signs" />
				<meta name="subcategory2" content="Safety Signs" />
				<meta name="subcategory3" content="Stop Signs" />
				<meta name="subcategory4" content="Outdoor Signs" />
				<meta name="subcategory5" content="Indoor Signs" />
				<meta name="price" content="Varies based on customization" />
				<meta name="availability" content="Made to Order" />
				<meta name="color" content="Multiple color options" />
				<meta name="size" content="Custom sizes available" />
				<meta name="material" content="Durable Materials" />
				<meta name="rating" content="4.8" />
				<meta name="reviewCount" content="85 Reviews" />
				<meta name="feature1" content="Energy-efficient lighting" />
				<meta name="feature2" content="Customizable designs" />
				<meta name="feature3" content="Suitable for indoor and outdoor use" />
				<meta name="feature4" content="Weather-resistant options" />
				<meta name="feature5" content="Variety of color and font choices" />
				<meta name="feature6" content="Eco-friendly LED technology" />
				// Add any other specific and relevant tags for your product
			</Helmet>
			<ShopTopBar
				onShowCountChange={handleShowCountChange}
				onSortByChange={handleSortByChange}
				onSearch={handleSearch}
			/>
			<div className="row">
				{filteredProducts.length > 0 ? (
					filteredProducts.map((product, index) => (
						<div key={index} className="col-lg-4 col-md-6 col-12">
							<div className="single-product">
								<div className="product-img">
									<Link
										to={`/custom-signs/${slugify(product.name)}?id=${
											product.id
										}`}
									>
										<img
											src={`https://www.call-trax.co.za/${product.image_url}`}
											className="img-fluid"
											alt={product.name}
										/>
									</Link>
								</div>
								<div className="product-content">
									<h3>
										{/* Get Product Catgory Id, then find it insidee the categories then find the sub category and make it a url */}
										<Link
											to={`/custom-signs/${slugify(product.name)}?id=${
												product.id
											}`}
										>
											{product.name}
										</Link>
									</h3>
								</div>
							</div>
						</div>
					))
				) : (
					<div className="no-results-message">
						No products found. Please try a different search.
					</div>
				)}
			</div>
		</div>
	);
};

export default ProductArea;

import React from "react";
import { Link } from "react-router-dom";
function PaintDetails() {
	return (
		<main id="main">
			{/* Services Details Page Title & Breadcrumbs */}
			<div className="page-title" data-aos="fade">
				<div className="heading">
					<div className="container">
						<div className="row d-flex justify-content-center text-center">
							<div className="col-lg-8">
								<h1>Project Management</h1>
								<p className="mb-0">
									Signs4SA boasts over 20 years of experience and a
									comprehensive skill set that uniquely positions us to manage
									signage projects effectively. From manufacturing and
									fabrication to installation and permitting, we are
									well-prepared to handle projects of any size. What sets us
									apart is their commitment to providing project management
									services for a seamless experience. We ensure that your
									brand's color requirements are met with precision, regardless
									of location. With Signs4SA, you can trust that your signage
									project will be managed by an experienced team dedicated to
									delivering excellent results. If you need assistance wiin our
									expanding signage network, we want to be regarded as an
									extension of your marketing team. Whether you have a concept,
									ideas, or even just a blank piece of paper, we can guide you
									through the entire process from start to finish.
								</p>
							</div>
						</div>
					</div>
				</div>
				<nav className="breadcrumbs">
					<div className="container">
						<ol>
							<li>
								<a href="/home">Home</a>
							</li>
							<li className="current">
								<a href="/services">Services Details</a>
							</li>
							<li className="current">Project Management</li>
						</ol>
					</div>
				</nav>
			</div>
			{/* End Page Title */}

			{/* Service-details Section - Services Details Page */}
			<section id="service-details" className="service-details">
				<div className="container">
					<div className="row gy-5">
						<div className="col-lg-12" data-aos="fade-up" data-aos-delay="100">
							{/* Services List */}
							<div className="service-box">
								<h4>Offered Project Management Services</h4>
								<div className="services-list">
									<a href="#fe">
										<i className="bi bi-circle"></i>
										<span>Fleet Graphics - From Start To Finish</span>
									</a>
									<a href="#na">
										<i className="bi bi-circle"></i>
										<span>National Projects</span>
									</a>
									<a href="#pr">
										<i className="bi bi-circle"></i>
										<span>Project Management</span>
									</a>
								</div>
							</div>
							{/* End Services List */}
							{/* ... additional service boxes if needed ... */}
						</div>
						<div className="col-lg-4" data-aos="fade-up" data-aos-delay="200">
							<img
								src="../assets/images/feature3.png"
								alt="Services"
								className="img-fluid services-img"
								style={{ height: "auto", width: "100%" }}
							/>

							<Link
								to="/ServicesPage"
								type="submit"
								className="btn-CustomToTheme"
								value="Get A Qoute"
							>
								<i className="bi bi-arrow-left"></i>
								<span>Back</span>
							</Link>
						</div>

						<div
							className="col-lg-8 ps-lg-5"
							data-aos="fade-up"
							data-aos-delay="200"
							id="fe"
						>
							{/* Services Image */}

							<h3>Fleet Graphics</h3>
							<p>
								When it comes to the most profitable form of advertising,
								Signs4SA believes that vehicle wrapping and signage take the
								lead. The reasons are compelling: these wraps can last up to 5
								years and provide 24/7 visibility. Essentially, it's a moving
								advertisement that ensures your brand is seen everywhere your
								vehicle goes. What's more, the cost per impression for vehicle
								wrapping is lower than any other advertising method, making it a
								highly cost-effective choice. Signs4SA recognizes that your
								brand is your business's most valuable asset, and consistency in
								representation is paramount. They understand the importance of
								maintaining your brand identity across all touchpoints.
							</p>

							<p>
								If you're considering vehicle wrapping or signage for your
								business, don't hesitate to get in touch with Signs4SA. Their
								team of sign experts is ready to assist you, offering expert
								opinions based on safety regulations and creating eye-catching
								signage tailored to your specific needs. Your brand's visibility
								and impact will receive a significant boost with their services.
							</p>

							{/* You might list some key benefits or features of your services here */}

							{/* Additional service details can be added here */}
							<Link
								to="/Contact"
								type="submit"
								className="btn-CustomToTheme"
								value="Get A Qoute"
							>
								Get A Qoute For Free!
							</Link>
						</div>

						<div className="col-lg-4" data-aos="fade-up" data-aos-delay="200">
							<img
								src="../assets/images/b.png"
								alt="Services"
								className="img-fluid services-img"
								style={{ height: "auto", width: "100%" }}
							/>

							<Link
								to="/ServicesPage"
								type="submit"
								className="btn-CustomToTheme"
								value="Get A Qoute"
							>
								<i className="bi bi-arrow-left"></i>
								<span>Back</span>
							</Link>
						</div>

						<div
							className="col-lg-8 ps-lg-5"
							data-aos="fade-up"
							data-aos-delay="200"
							id="na"
						>
							{/* Services Image */}

							<h3>National Accounts</h3>
							<p>
								Signs4SA offers a National Accounts program to assist businesses
								with their nationwide signage projects. Their strategic
								positioning and experienced teams ensure that your signage
								project becomes a reality without exceeding your budget or
								timeline. By engaging their Project Management team, you can
								focus on running your business while they take care of the
								signage process from start to finish.
							</p>

							<p>Their all-inclusive services encompass:</p>

							{/* You might list some key benefits or features of your services here */}
							<ul>
								<li>
									<i className="bi bi-check-circle"></i> Brand Culture
									Evaluation.
								</li>
								<li>
									<i className="bi bi-check-circle"></i> Sign Planning and
									Design (Creatives).
								</li>
								<li>
									<i className="bi bi-check-circle"></i> Manufacture.
								</li>
								<li>
									<i className="bi bi-check-circle"></i> Quality Assurance.
								</li>
								<li>
									<i className="bi bi-check-circle"></i> Installation.
								</li>
							</ul>
							{/* Additional service details can be added here */}

							<p>
								By choosing Signs4SA for your national sign projects, you
								benefit from a single point of contact and consistent brand
								representation throughout the process. From estimation and
								design to production, logistics, timing, and installation, they
								cover it all. If you're interested in their services for your
								business or need expert advice based on safety regulations for
								your signage project, don't hesitate to reach out to Signs4SA.
								Their sign experts are ready to assist you every step of the
								way, ensuring your signage needs are met effectively.
							</p>
							<Link
								to="/Contact"
								type="submit"
								className="btn-CustomToTheme"
								value="Get A Qoute"
							>
								Get A Qoute For Free!
							</Link>
						</div>

						<div className="col-lg-4" data-aos="fade-up" data-aos-delay="200">
							<img
								src="../assets/images/po.png"
								alt="Services"
								className="img-fluid services-img"
								style={{ height: "auto", width: "100%" }}
							/>

							<Link
								to="/ServicesPage"
								type="submit"
								className="btn-CustomToTheme"
								value="Get A Qoute"
							>
								<i className="bi bi-arrow-left"></i>
								<span>Back</span>
							</Link>
						</div>

						<div
							className="col-lg-8 ps-lg-5"
							data-aos="fade-up"
							data-aos-delay="200"
							id="pr"
						>
							{/* Services Image */}

							<h3>Project Management</h3>
							<p>
								When it comes to whether you'll need council approval for your
								signage project, it largely depends on the nature of the signage
								and the regulations of your local council. Different councils
								may have varying rules and requirements. Some may allow the
								replacement of existing signage with new graphics without formal
								approval, while others might mandate that you seek development
								approvals for any changes to signage. Council regulations can
								also differ when it comes to external signage versus signage
								placed behind windows. It's advisable to check with your local
								council before proceeding with any signage work to ensure you
								are in compliance with their specific guidelines.
							</p>

							<p>
								Signs4SA can be a valuable resource in this regard. They can
								assist you by providing the necessary plans and images required
								for your council application, typically at a standard rate. If
								your business is located within a shopping center, there are
								often specific guidelines provided by the Center Management that
								tenants are required to follow. Signs4SA can guide you through
								this process, helping you understand the center's requirements
								and ensuring that your signage not only aligns with their
								standards but also meets your business promotion needs
								effectively. If you're considering signage for your business or
								need assistance navigating the intricacies of council approvals
								and shopping center guidelines, don't hesitate to reach out to
								Signs4SA. Their sign experts are ready to provide expert advice
								and create eye-catching signage tailored to your specific
								requirements.
							</p>

							{/* You might list some key benefits or features of your services here */}

							{/* Additional service details can be added here */}

							<Link
								to="/Contact"
								type="submit"
								className="btn-CustomToTheme"
								value="Get A Qoute"
							>
								Get A Qoute For Free!
							</Link>
						</div>
					</div>
				</div>
			</section>
			{/* End Service-details Section */}
		</main>
	);
}

export default PaintDetails;

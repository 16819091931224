import React, { useState, useEffect } from "react";
import Footer from "../components/Footer";

const BlogPage = () => {
	const [blogs, setBlogs] = useState([]);

	// Fetch blogs from your PHP backend
	useEffect(() => {
		fetch("https://www.call-trax.co.za/assets/vendor/Get_All_Posts.php") // Replace with your actual URL
			.then((response) => response.json())
			.then((data) => {
				setBlogs(data); // Assuming your PHP script returns an array of blogs
			})
			.catch((error) => {
				console.error("Error fetching blogs:", error);
			});
	}, []);

	return (
		<main id="main">
			{/* Blog Page Title & Breadcrumbs */}
			<div data-aos="fade" className="page-title">
				<div className="heading">
					<div className="container">
						<div className="row d-flex justify-content-center text-center">
							<div className="col-lg-8">
								<h1>Blog</h1>
								<p className="mb-0">Keep Up with the latest signage news</p>
							</div>
						</div>
					</div>
				</div>
				<nav className="breadcrumbs">
					<div className="container">
						<ol>
							<li>{/* <Link to="/">Home</Link> */}</li>
							<li className="current">Blog</li>
						</ol>
					</div>
				</nav>
			</div>

			{/* Blog Section - Blog Page */}
			<section id="blog" className="blog">
				<div className="container" data-aos="fade-up" data-aos-delay="100">
					<div className="row gy-4 posts-list">
						{blogs.length > 0 ? (
							blogs.map((blog, index) => (
								<div key={index} className="col-xl-4 col-lg-6">
									<article>
										<div className="post-img">
											<img src={blog.image_url} alt="" className="img-fluid" />
										</div>
										<p className="post-category">{blog.category}</p>
										<h2 className="title">
											<a href={`blog-details.html?blogId=${blog.id}`}>
												{blog.title}
											</a>
										</h2>
										{/* Additional blog details */}
									</article>
								</div>
							))
						) : (
							<p>No blog posts available.</p>
						)}
					</div>

					{/* Pagination */}
					<div className="pagination d-flex justify-content-center">
						<ul>
							{/* <li>
                <a href="#">1</a>
              </li>
              <li className="active">
                <a href="#">2</a>
              </li>
              <li>
                <a href="#">3</a>
              </li> */}
						</ul>
					</div>
				</div>
			</section>

			{/* Footer Section */}
			<Footer />
		</main>
	);
};

export default BlogPage;

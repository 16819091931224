import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const PortfolioItem = ({
	imgSrc,
	title,
	description,
	detailsLink,
	galleryLink,
}) => {
	return (
		<div className="col-lg-4 col-md-6 portfolio-item isotope-item">
			<img src={imgSrc} className="img-fluid" alt={title} />
			<div className="portfolio-info">
				<h4>{title}</h4>
				<p>{description}</p>
				<a href={galleryLink} title={title} className="glightbox preview-link">
					<i className="bi bi-zoom-in"></i>
				</a>
				<Link to={"/shop"} title="More Details" className="details-link">
					<i className="bi bi-link-45deg"></i>
				</Link>
			</div>
		</div>
	);
};

const PortfolioSection = () => {
	// Define a state variable 'portfolioItems' and a function 'setPortfolioItems'
	const [portfolioItems, setPortfolioItems] = useState([]);

	// This useEffect hook runs when the component is mounted
	useEffect(() => {
		// Fetch data from the specified URL
		fetch("https://www.call-trax.co.za/assets/vendor/Get_All_Signs.php")
			.then((response) => response.json()) // Parse the response as JSON
			.then((data) => setPortfolioItems(data)) // Set the fetched data in 'portfolioItems' state
			.catch((error) => console.error("Error:", error)); // Handle any errors
	}, []); // The empty dependency array means this effect runs only once on mount

	return (
		<section id="portfolio" className="portfolio">
			<div className="container section-title" data-aos="fade-up">
				<h2>Our Creative Showcase</h2>
				<p>
					Explore our diverse portfolio of signage projects, each crafted to
					perfection...
				</p>
			</div>

			<div className="container">
				<div
					className="row gy-4 isotope-container"
					data-aos="fade-up"
					data-aos-delay="200"
				>
					{portfolioItems.map((item) => (
						<PortfolioItem
							key={item.id}
							imgSrc={process.env.PUBLIC_URL + "/" + item.image_url}
							title={item.name}
							description={item.description}
							detailsLink="/shop"
							galleryLink={process.env.PUBLIC_URL + "/" + item.image_url}
						/>
					))}
				</div>
			</div>
		</section>
	);
};

export default PortfolioSection;

import React from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import Sidebar from "../components/Sidebar";
import ProductArea from "../components/ProductArea";
import { Helmet } from "react-helmet";
import "../assets/shop/css/style.css";
import "../assets/shop/css/bootstrap.css";
import "../assets/shop/css/magnific-popup.min.css";
import "../assets/shop/css/font-awesome.css";
import "../assets/shop/css/jquery.fancybox.min.css";
import "../assets/shop/css/themify-icons.css";
import "../assets/shop/css/niceselect.css";
import "../assets/shop/css/animate.css";
import "../assets/shop/css/flex-slider.min.css";
import "../assets/shop/css/slicknav.min.css";
import "../assets/shop/css/style.css";
import "../assets/shop/css/responsive.css";

const ShopPage = () => {
	return (
		<div className="js">
			<Helmet>
				<title>
					LED Neon Signs - Customizable & Energy-Efficient - SignShopOnline
				</title>
				<meta
					name="description"
					content="Create a vibrant and inviting atmosphere with our customizable LED neon signs. Perfect for businesses, events, and home decor. Energy-efficient and long-lasting."
				/>
				<meta
					name="keywords"
					content="LED neon signs, custom neon signs, energy-efficient signage, business signs, home decor signs, event signage, SignShopOnline"
				/>
				<meta name="product" content="LED Neon Sign" />
				<meta name="brand" content="SignShopOnline" />
				<meta name="category" content="Neon Signage" />
				<meta name="price" content="Varies based on customization" />
				<meta name="availability" content="Made to Order" />
				<meta name="color" content="Multiple color options" />
				<meta name="size" content="Custom sizes available" />
				<meta name="material" content="Durable LED tubing" />
				<meta name="rating" content="4.8" />
				<meta name="reviewCount" content="85 Reviews" />
				<meta name="feature1" content="Energy-efficient lighting" />
				<meta name="feature2" content="Customizable designs" />
				<meta name="feature3" content="Suitable for indoor and outdoor use" />
				// Add any other specific and relevant tags for your product
			</Helmet>

			<Breadcrumbs />
			<section className="product-area shop-sidebar shop section">
				<div className="container">
					<div className="row">
						<Sidebar />
						<ProductArea />
					</div>
				</div>
			</section>
		</div>
	);
};

export default ShopPage;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function CreativeDetails() {
	const [servicesData, setServicesData] = useState(null);
	const [error, setError] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch("https://signs4sa.co.za/api.php");
				if (!response.ok) {
					throw new Error("Network response was not ok.");
				}
				const jsonData = await response.json();
				setServicesData(jsonData);
			} catch (error) {
				setError(error.message);
			}
		};

		fetchData();
	}, []);

	return (
		<main id="main">
			{/* Services Details Page Title & Breadcrumbs */}
			<div className="page-title" data-aos="fade">
				<div className="heading">
					<div className="container">
						<div className="row d-flex justify-content-center text-center">
							<div className="col-lg-8">
								<h1>Creative Setvices</h1>
								<p className="mb-0">
									We are more than just a signage company; we are an integral
									part of your marketing team. In today's fast-paced business
									world, you don't have the time to micromanage every aspect of
									your business. At Signs4SA, we are here to relieve you of
									those headaches and allow you to focus on running your
									company. We ensure that your brand, image, and personality are
									consistently represented in the best possible way. Within our
									expanding signage network, we want to be regarded as an
									extension of your marketing team. Whether you have a concept,
									ideas, or even just a blank piece of paper, we can guide you
									through the entire process from start to finish.
								</p>
							</div>
						</div>
					</div>
				</div>
				<nav className="breadcrumbs">
					<div className="container">
						<ol>
							<li>
								<a href="/home">Home</a>
							</li>
							<li className="current">
								<a href="/services">Services Details</a>
							</li>

							<li className="current">Creative Services</li>
						</ol>
					</div>
				</nav>
			</div>
			{/* End Page Title */}

			{/* Service-details Section - Services Details Page */}
			<section id="service-details" className="service-details">
				<div className="container">
					<div className="row gy-5">
						<div className="col-lg-12" data-aos="fade-up" data-aos-delay="100">
							{/* Services List */}
							<div className="service-box">
								<h4>Offered Creative Services</h4>
								<div className="services-list">
									<a href="#brandaudits">
										<i className="bi bi-circle"></i>
										<span>Brand Audits</span>
									</a>
									<a href="#design">
										<i className="bi bi-circle"></i>
										<span>Designs</span>
									</a>
								</div>
							</div>
							{/* End Services List */}
							{/* ... additional service boxes if needed ... */}
						</div>

						<div className="col-lg-4" data-aos="fade-up" data-aos-delay="200">
							<img
								src="./assets/images/s.png"
								alt="Services"
								className="img-fluid services-img"
								style={{ height: "750px" }}
							/>
						</div>

						<div
							className="col-lg-8 ps-lg-5"
							data-aos="fade-up"
							data-aos-delay="200"
							id="brandaudits"
						>
							{/* Services Image */}

							<h3>Brand Audits</h3>
							<p>
								For a professional Brand Audit & Consultation, look no further!
								At Signs4SA, we believe in our knowledge and experience to
								provide you with expert advice on improving and managing your
								brand. Our motto is to recommend reviewing your brand's identity
								every 5-7 years to ensure it remains current and at the
								forefront of your industry. Conducting a brand audit is the
								essential first step in evaluating your brand, its values, its
								mission, and its position in the marketplace before embarking on
								a new brand identity.
							</p>

							<p>
								A brand audit encompasses various elements that are crucial in
								the ever-changing business world. Without periodic reevaluation,
								you might inadvertently target the wrong audience or pursue
								ineffective marketing strategies. By regularly reviewing your
								brand, you can stay ahead of your competitors and ensure
								effective communication with your target audience. Understanding
								your competition is vital in this process. In a comprehensive
								brand audit, Signs4SA will focus on the following elements:
							</p>

							{/* You might list some key benefits or features of your services here */}
							<ul>
								<li>
									<i className="bi bi-check-circle"></i> Identifying your
									competitors and similar brands.
								</li>
								<li>
									<i className="bi bi-check-circle"></i> Analyzing your brand
									language and making comparisons.
								</li>
								<li>
									<i className="bi bi-check-circle"></i> Evaluating your visual
									identity with the aim of improvement.
								</li>
								<li>
									<i className="bi bi-check-circle"></i> Studying target
									audiences to understand how they perceive your brand and
									similar ones.
								</li>
								<li>
									<i className="bi bi-check-circle"></i> Establishing your
									brand's positioning in the industry and identifying ways to
									enhance it.
								</li>
							</ul>
							{/* Additional service details can be added here */}
							<p>
								Our commitment to your brand doesn't end with the completion of
								the brand audit. We can also assist you in implementing
								strategies to enhance your brand based on the insights gained
								during the audit. These services can include designing a new
								logo and exploring new signage and branding options. With our
								dedicated team by your side, you can rest assured that your
								brand will gain the attention it deserves! Contact us today to
								inquire about a brand audit for your business and get ready to
								reap the benefits. Interested in our services for your business?
								Give us a call, and our sign expert will be delighted to assist
								you, offering expert opinions based on safety regulations and
								creating eye-catching signage that meets all your specific
								needs.
							</p>

							<Link
								to="/Contact"
								type="submit"
								className="btn-CustomToTheme"
								value="Get A Qoute"
							>
								Get A Qoute For Free!
							</Link>
						</div>

						<div className="col-lg-4" data-aos="fade-up" data-aos-delay="200">
							<img
								src="./assets/images/r.png"
								alt="Services"
								className="img-fluid services-img"
								style={{ height: "400px" }}
							/>
						</div>

						<div
							className="col-lg-8 ps-lg-5"
							data-aos="fade-up"
							data-aos-delay="200"
							id="design"
						>
							{/* Services Image */}

							<h3>Design</h3>
							<p>
								Every mode of customer interaction conveys a message about your
								identity, from the colors you choose to the fonts and graphics
								you employ. Is your branding and graphic design effectively
								communicating the message you want your customers to receive? Is
								it compelling them to take action? At Signs4SA, our team remains
								current with the latest design and marketing trends to ensure
								your message is impactful.
							</p>

							<p>
								We specialize in providing custom, high-end designs quickly and
								affordably. Whether you have hard or soft copy images, we can
								efficiently convert them into print-ready digital files.
								Signs4SA's sign center excels at transforming your ideas into
								quality designs suitable for use in printed materials, signage,
								and more.
							</p>

							{/* You might list some key benefits or features of your services here */}

							{/* Additional service details can be added here */}
							<p>
								For those without existing artwork, worry not. We boast an
								extensive library of thousands of award-winning, high-resolution
								photos, images, illustrations, video clips, sound files, fonts,
								and backgrounds, all available in sizes suitable for posters,
								signs, trade show displays, and beyond. Interested in enhancing
								your business's image? Give us a call, and our sign experts will
								be delighted to assist you. We offer expert guidance based on
								safety regulations and can create eye-catching signage tailored
								to your specific needs.
							</p>

							<Link
								to="/Contact"
								type="submit"
								className="btn-CustomToTheme"
								value="Get A Qoute"
							>
								Get A Qoute For Free!
							</Link>
						</div>
					</div>
				</div>
			</section>
			{/* End Service-details Section */}
		</main>
	);
}

export default CreativeDetails;

import React from "react";

const HeroSection = () => {
	// Handle form submission if necessary
	const handleSubmit = (event) => {
		event.preventDefault();
		// Add form submission logic here
	};

	// Finalized And Know To be Working
	return (
		<section id="hero" className="hero">
			<div className="container">
				<div className="row">
					<div className="col-lg-10 headerText">
						<h2 data-aos="fade-up" data-aos-delay="100">
							<span style={{ fontWeight: "700", color: "red" }}>
								Shine Bright
							</span>
							<br />
							<span style={{ fontWeight: "600", color: "white" }}>
								For Cheap!
							</span>
						</h2>
						<p data-aos="fade-up" data-aos-delay="200">
							At Sign Shop Online, we specialize in creating custom, high-impact
							signage solutions that capture attention and convey your brand's
							message. Explore our innovative designs and see how we can enhance
							your business visibility.
						</p>
					</div>

					<div className="col-lg-5">
						<form
							className="sign-up-form d-flex"
							data-aos="fade-up"
							data-aos-delay="300"
							onSubmit={(e) => {
								e.preventDefault();
								const subject = e.target.subject.value;
								const body = e.target.body.value;
								window.location.href = `mailto:info@signs4sa.co.zasubject=${encodeURIComponent(
									subject
								)}&body=${encodeURIComponent(body)}`;
							}}
						>
							<input
								type="text"
								name="subject"
								className="form-control"
								placeholder="Subject"
							/>
							<textarea
								name="body"
								className="form-control"
								placeholder="What Are you looking for?"
							></textarea>
							<input
								type="submit"
								className="btn btn-primary"
								value="Get In Touch"
							/>
						</form>
					</div>
				</div>
			</div>
		</section>
	);
};

export default HeroSection;

import React from "react";
import { Link } from "react-router-dom";

function InstallDetails() {
	return (
		<main id="main">
			{/* Services Details Page Title & Breadcrumbs */}

			<div className="page-title" data-aos="fade">
				<div className="heading">
					<div className="container">
						<div className="row d-flex justify-content-center text-center">
							<div className="col-lg-8">
								<h1>Installations</h1>
								<p className="mb-0">
									Signs4SA specializes in high-quality signage solutions for
									businesses. We offer a wide range of custom signage including
									indoor, outdoor, vehicle wraps, and safety signs. Our focus is
									on creating eye-catching, durable signs using state-of-the-art
									technology, ensuring your brand stands out effectively. Our
									dedicated team guarantees exceptional service, from design to
									installation, to meet your specific branding needs.
								</p>
							</div>
						</div>
					</div>
				</div>
				<nav className="breadcrumbs">
					<div className="container">
						<ol>
							<li>
								<a href="/home">Home</a>
							</li>
							<li className="current">
								<a href="/services">Services Details</a>
							</li>
							<li className="current"> Installations</li>
						</ol>
					</div>
				</nav>
			</div>
			{/* End Page Title */}

			{/* Service-details Section - Services Details Page */}
			<section id="service-details" className="service-details">
				<div className="container">
					<div className="row gy-5">
						<div className="col-lg-4" data-aos="fade-up" data-aos-delay="200">
							<img
								src="./assets/images/v.png"
								alt="Services"
								className="img-fluid services-img"
								style={{ height: "auto", width: "100%" }}
							/>

							<Link
								to="/ServicesPage"
								type="submit"
								className="btn-CustomToTheme"
								value="Get A Qoute"
							>
								<i className="bi bi-arrow-left"></i>
								<span>Back</span>
							</Link>
						</div>

						<div
							className="col-lg-8 ps-lg-5"
							data-aos="fade-up"
							data-aos-delay="200"
							id="brandaudits"
						>
							{/* Services Image */}

							<h3>Installation Services</h3>
							<p>
								Signs4SA is your trusted partner for comprehensive sign
								installation services that leave a lasting impression on your
								business. Our team of experts excels in the art of sign
								placement, ensuring that your brand message is delivered
								effectively. From prominent outdoor signs that catch the eye of
								passersby to carefully designed indoor signage that enhances the
								customer experience, Signs4SA has the skills and experience to
								make your vision a reality.
							</p>
							<h4 style={{ fontSize: "20px", fontWeight: "bold" }}>
								Why Choose Us{" "}
							</h4>
							<p>
								We specialize in installing illuminated signs, vehicle wraps,
								banners, digital signage, and more, all while adhering to the
								highest safety standards. Our commitment doesn't stop at
								installation; we also offer maintenance and repair services to
								keep your signs looking their best. With Signs4SA, you can trust
								that your brand's visual identity will shine through every
								aspect of your signage, creating a powerful connection with your
								audience.
							</p>

							<h4 style={{ fontSize: "20px", fontWeight: "bold" }}>
								Our Services{" "}
							</h4>
							{/* You might list some key benefits or features of your services here */}
							<ul>
								<li>
									<i className="bi bi-check-circle"></i> Outdoor Sign
									Installation
								</li>
								<li>
									<i className="bi bi-check-circle"></i> Indoor Sign
									Installation
								</li>
								<li>
									<i className="bi bi-check-circle"></i> Illuminated Sign
									Installation
								</li>
								<li>
									<i className="bi bi-check-circle"></i> Vehicle Wrap
									Installation
								</li>
								<li>
									<i className="bi bi-check-circle"></i> Banner Installation,
									etc.
								</li>
							</ul>
							{/* Additional service details can be added here */}

							<Link
								to="/Contact"
								type="submit"
								className="btn-CustomToTheme"
								value="Get A Qoute"
							>
								Get A Qoute For Free!
							</Link>
						</div>
					</div>
				</div>
			</section>
			{/* End Service-details Section */}
		</main>
	);
}

export default InstallDetails;

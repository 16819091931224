// Imports Allow us to use the pages
import React, { Profiler } from "react";
import AboutSection from "../components/AboutSection";
import StatsSection from "../components/StatsSection";
import HeroSection from "../components/Hero";
import ServicesSection from "../components/Services";
import FeaturesSection from "../components/FeaturesSection";
import PortfolioSection from "../components/Portfolio";
import FaqSection from "../components/FaqSection";
import CallToAction from "../components/CallToAction";
import TestimonialsSection from "../components/TestimonialsSection";
import NewsSection from "../components/NewsSection";
import ContactSection from "../components/ContactUs";
import Footer from "../components/Footer";
import ClientsSection from "../components/ClientsSection";

// React Then Export this Function Called Home
const Home = () => {
	return (
		<main id="main">
			{/* Hero Section */}
			<HeroSection />

			{/* People We have worked With */}
			<ClientsSection />

			{/* About Us */}
			<AboutSection />

			{/* Stats Counter Section */}
			<StatsSection />
			{/* Services Section */}
			<ServicesSection />

			{/* Features Section */}
			<FeaturesSection />

			{/* Portfolio Section */}
			<PortfolioSection />

			{/* Faq Section */}
			<FaqSection />

			{/* Call To action */}
			<CallToAction />

			{/* Testimonials */}
			<TestimonialsSection />

			{/* Blog Posts */}
			<NewsSection />

			{/* Contact us */}
			{/* Removed For the time being */}
			{/* <ContactSection /> */}

			{/* Footer Section */}
			<Footer />
		</main>
	);
};

// The Code then Gets Exported
export default Home;
